import { Button } from "./Button";
import { IoIosWarning } from "react-icons/io";
import { useForm } from "react-hook-form";
import emailjs from "@emailjs/browser";
import { useEffect, useState } from "react";
import { useInView } from "react-hook-inview";
import { useAnimation, motion } from "framer-motion";

const variants = {
	visible: {
		opacity: 1,
		scale: 1,
		y: 0,
		transition: { duration: 0.4 },
	},
	hidden: { opacity: 0, scale: 0.85, y: 50 },
};

export const ContactForm = () => {
	const [ref, isVisible] = useInView({
		threshold: 0.5,
		// unobserveOnEnter: true,
	});
	const controls = useAnimation();

	const {
		register,
		handleSubmit,
		reset,
		formState: { errors },
	} = useForm();
	const [loading, setLoading] = useState(false);
	const [success, setSuccess] = useState("");
	const [error, setError] = useState("");

	useEffect(() => {
		if (isVisible) {
			controls.start("visible");
		} else {
			controls.start("hidden");
		}
	}, [controls, isVisible]);

	useEffect(() => {
		if (error || success) {
			setTimeout(() => {
				setError("");
				setSuccess("");
			}, 7000);
		}
	}, [success, error]);

	const onSubmit = () => {
		setLoading(true);
		const form = document.getElementById("form");

		emailjs
			.sendForm(
				process.env.REACT_APP_MAIL_SERVICE_ID,
				process.env.REACT_APP_MAIL_TEMPLATE_ID,
				form,
				{
					publicKey:
						process.env
							.REACT_APP_MAIL_PUBLIC_KEY,
				}
			)
			.then(
				() => {
					setLoading(false);
					setSuccess(
						"Thank you! Your message has been sent."
					);
					reset();
				},
				(error) => {
					setLoading(false);
					setError(error.text);
				}
			);
	};

	return (
		<motion.div
			ref={ref}
			animate={controls}
			initial="hidden"
			variants={variants}
		>
			<form
				id="form"
				className="form border-2 dark:bg-transparent bg-white dark:border-gray-500 border-gray-300 border-solid p-6 rounded-xl mt-6 lg:mt-10"
				onSubmit={handleSubmit(onSubmit)}
				noValidate
			>
				<div className="form__group mt-6">
					<label htmlFor="fullName">
						Full name
					</label>
					<input
						className="block w-full p-1 mt-2 outline-none  bg-transparent border-b-2  dark:focus:border-blue-500 focus:border-stone-950  dark:border-gray-500 border-gray-300 border-solid"
						id="fullName"
						type="text"
						{...register("fullName", {
							required: true,
						})}
					></input>
					{errors.fullName && (
						<p
							role="alert"
							className="flex items-center mt-2 text-sm text-red-500"
						>
							<IoIosWarning className="mr-2" />{" "}
							Full name is required
						</p>
					)}
				</div>
				<div className="form__group mt-6">
					<label htmlFor="email">
						Email address
					</label>
					<input
						className="block w-full  p-1 mt-2 outline-none  bg-transparent border-b-2  dark:focus:border-blue-500 focus:border-stone-950  dark:border-gray-500 border-gray-300 border-solid"
						id="email"
						type="email"
						{...register("email", {
							required:
								"Email address is required",
							pattern: {
								value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
								message:
									"Invalid email address",
							},
						})}
					></input>
					{errors.email && (
						<p
							role="alert"
							className="flex items-center mt-2 text-sm text-red-500"
						>
							<IoIosWarning className="mr-2" />{" "}
							{errors.email.message}
						</p>
					)}
				</div>
				<div className="form__group mt-6">
					<label htmlFor="message">Message</label>
					<textarea
						className="block w-full mt-2 outline-none bg-transparent border-b-2 dark:focus:border-blue-500 focus:border-stone-950 dark:border-gray-500 border-gray-300 border-solid"
						name="message"
						id="message"
						cols="30"
						rows="10"
						{...register("message", {
							required: true,
						})}
					></textarea>
					{errors.message && (
						<p
							role="alert"
							className="flex items-center mt-2 text-sm text-red-500"
						>
							<IoIosWarning className="mr-2" />{" "}
							Message is required
						</p>
					)}
				</div>

				{error ||
					(success && (
						<div className="mt-10">
							<p
								className={`${
									error
										? "text-red-500"
										: "text-green-500"
								}`}
							>
								{error ? error : success}
							</p>
						</div>
					))}

				<div className="mt-10">
					<Button
						type="submit"
						label="Send message"
						loading={loading}
						disabled={
							errors.fullName ||
							errors.email ||
							errors.message
						}
					/>
				</div>
			</form>
		</motion.div>
	);
};
