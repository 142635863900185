import { Navigation } from "./Navigation";
import { HeaderCard } from "./HeaderCard";
// import { Switch } from "./Switch";
// import { TechStack } from "./TechStack";

export const Header = ({ onModeChange, darkMode }) => {
	return (
		<header className="relative z-[1] lg:sticky md:relative lg:w-1/2 w-full top-0 flex max-h-screen dark:bg-stone-950 bg-gray-100 pt-0 pb-0 lg:pt-6 lg:pb-6 xl:pb-12 xl:pt-12">
			<div className="flex w-full grow md:pr-0 md:border-0 flex-col text-gray-100 lg:pr-6 xl:pr-12 lg:border-r-2 dark:border-gray-500 border-gray-300 border-solid">
				<HeaderCard />
				<Navigation />
				{/* Todo: decide of ChatGPT integration */}
				{/* <TechStack /> */}
				{/* <div class="mt-10">
					<Switch
						onModeChange={(e) =>
							onModeChange(e.target.checked)
						}
						darkMode={darkMode}
					/>
				</div> */}
			</div>
		</header>
	);
};
