import "./scss/App.scss";
import { Header } from "./components/Header";
import { Main } from "./components/Main";
import { useState } from "react";

function App() {
	const [darkMode, setDarkMode] = useState(false);

	// Todo: fix dark mode
	// useEffect(() => {
	// 	setDarkMode(
	// 		window.matchMedia &&
	// 			window.matchMedia(
	// 				"(prefers-color-scheme: dark)"
	// 			).matches
	// 	);
	// }, []);

	return (
		<div
			data-mode={darkMode ? "dark" : "light"}
			className={"dark:bg-stone-950 bg-gray-100"}
		>
			<div className="container mx-auto w-full lg:px-12 px-0 lg:px-6 box-border flex lg:flex-row flex-col gap-x-6 xl:gap-x-12 scroll-pt-36">
				<Header
					onModeChange={(isOn) =>
						setDarkMode(isOn)
					}
					darkMode={darkMode}
				/>
				<Main />
			</div>
		</div>
	);
}

export default App;
