import { useInView } from "react-hook-inview";
import { useAnimation, motion } from "framer-motion";
import { useEffect } from "react";

const variants = {
	visible: {
		opacity: 1,
		scale: 1,
		y: 0,
		transition: { duration: 0.4 },
	},
	hidden: { opacity: 0, scale: 0.85, y: 50 },
};

export const ExperienceCard = ({
	title,
	type,
	company,
	range,
	copy,
	location,
	links,
}) => {
	const [ref, isVisible] = useInView({
		threshold: 0.5,
		// unobserveOnEnter: true,
	});
	const controls = useAnimation();

	useEffect(() => {
		if (isVisible) {
			controls.start("visible");
		} else {
			controls.start("hidden");
		}
	}, [controls, isVisible]);

	return (
		<motion.div
			ref={ref}
			animate={controls}
			initial="hidden"
			variants={variants}
			className={`experienceCard border-2 p-4 dark:border-gray-500 border-gray-300 dark:bg-transparent bg-white rounded-xl border-solid grid grid-cols-9 xl:gap-4 dark:text-gray-100 mt-10`}
		>
			<div className="experienceCard__line h-0.5 dark:bg-gray-500 bg-gray-300 "></div>
			<div className="xl:col-span-3 col-span-9">
				<span className="uppercase text-sm dark:text-gray-400 text-gray-500">
					{range}
				</span>
			</div>
			<div className="xl:col-span-6 col-span-9">
				<p className="uppercase text-xl">{title}</p>
				<p className=" dark:text-gray-400 text-gray-500">
					{company} · {location} · {type}
				</p>
				<ul className="mt-6 space-y-2 pl-4 xl:pl-0 text-sm list-outside list-[square]">
					{copy.map((item, i) => (
						<li key={i}>{item}</li>
					))}
				</ul>
				{links ? (
					<div className="mt-4 text-sm overflow-hidden sm:overflow-visible">
						<p className="dark:text-gray-400 text-gray-500 uppercase">
							Few recent project links:
						</p>
						{links.map((link) => (
							<div
								className="mt-4"
								key={link.href}
							>
								<p>{link.label}</p>
								<a
									href={link.href}
									target="_blank"
									className="block text-blue-500 mt font-regular underline cursor-pointer"
								>
									{link.href}
								</a>
							</div>
						))}
					</div>
				) : (
					<></>
				)}
			</div>
		</motion.div>
	);
};
