export const Button = ({
	target,
	href,
	label,
	icon,
	type,
	loading,
	disabled,
}) => {
	const Icon = icon;

	if (href) {
		return (
			<a
				className="button overflow-hidden rounded-xl border-2 focus:text-gray-200 hover:text-gray-200 hover:border-transparent dark:focus:text-stone-950 dark:hover:text-stone-950"
				href={href}
				target={target}
			>
				<div className="button__content px-5 py-3">
					<span>{label}</span>
					{icon ? <Icon></Icon> : <></>}
				</div>
				<div className="button__overlay dark:bg-gradient-to-r dark:to-blue-500 dark:from-indigo-600 bg-gradient-to-l to-stone-800 from-stone-700"></div>
			</a>
		);
	} else {
		return (
			<button
				type={type}
				className="button overflow-hidden rounded-xl border-2 focus:text-gray-200 hover:text-gray-200 hover:border-transparent dark:focus:text-stone-950 dark:hover:text-stone-950"
				disabled={disabled || loading}
			>
				<div className="button__content px-5 py-3">
					<div className="button__spinner"></div>
					<span>
						{!loading ? label : "Sending..."}
					</span>
					{icon ? <Icon></Icon> : <></>}
				</div>
				<div className="button__overlay dark:bg-gradient-to-r dark:to-blue-500 dark:from-indigo-600 bg-gradient-to-l to-stone-800 from-stone-700"></div>
			</button>
		);
	}
};
