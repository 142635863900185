export const experience = [
	{
		title: "Frontend Developer",
		company: "Fishawack Health (via Everse)",
		type: "Remote",
		location: "PL/UK",
		range: "June 2022 - present",
		copy: [
			"Develop websites, web applications, and sales booth applications for medical conferences for our UK-based client.",
			"Write clean, modular, and well-documented code in Vue.js that follows industry best practices and company coding standards.",
			"Work closely with project managers to understand project requirements and timelines and communicate progress and roadblocks in a timely and transparent manner.",
			"Create project estimates that accurately reflect the scope of work, resource requirements, and timelines.",
			"Work together with backend developers to integrate frontend components with server-side APIs and databases.",
			"Collaborate with designers to ensure the feasibility of a project and that the final product aligns with the design.",
			"Test and debug applications to ensure high-quality and positive user experience.",
		],
		links: [
			{
				label: "Avalere Health",
				href: "https://avalerehealth.com",
			},
			{
				label: "Expert Partnership",
				href: "https://expertpartnership.com",
			},
		],
	},
	{
		title: "Frontend Developer",
		company: "Meej App",
		type: "Remote",
		location: "PL/UK",
		range: "January 2023 - present",
		intro: "Co-develop 'Meej', a user-centric strength training app, in a collaborative effort with a UK-based professional handling backend development. The project, available on Apple and Google Play stores, begins with a focus on creating a Minimum Viable Product (MVP) and continues to evolve with ongoing enhancements and feature additions.",
		copy: [
			"Develop the frontend of 'Meej' using Vue.js 3, ensuring high performance and responsiveness of the application.",
			"Utilize SCSS for efficient and scalable styling, enhancing the app's visual aesthetics and user experience.",
			"Build and manage reusable components, contributing to a modular and maintainable codebase.",
			"Collaborate closely with a UK-based PM to align frontend development with backend functionalities and overall project objectives.",
			"Actively involved in the iterative design process, incorporating feedback into the ongoing development of the app.",
			"Ensure that the app's interface is intuitive and user-friendly, adhering to best practices in UI/UX design.",
		],
		links: [
			{
				label: "Meej · iOS",
				href: "https://apps.apple.com/vn/app/meej/id6450255756",
			},
			{
				label: "Meej · Google Play",
				href: "https://play.google.com/store/apps/details?id=com.ench.meej",
			},
		],
	},
	{
		title: "Intern / Junior Frontend Developer",
		company: "Everse",
		type: "Hybrid",
		location: "PL",
		range: "Feb 2021 - May 2022",
		copy: [
			"Worked with senior developers to build and maintain web applications.",
			"Collaborated with designers to implement responsive layouts and user interfaces.",
			"Wrote clean and maintainable code using HTML, CSS, and JavaScript, with a specific focus on developing and enhancing Vue.js framework skills.",
			"Debugged and troubleshooted issues with web pages and web applications.",
			"Followed coding best practices and adhered to coding standards.",
			"Continuously improved technical skills and knowledge through self-learning and training opportunities, with a particular emphasis on enhancing Vue.js framework skills and knowledge.",
		],
	},
];
