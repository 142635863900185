import { useState, useEffect } from "react";
import { useInView } from "react-hook-inview";
import { useAnimation, motion } from "framer-motion";

const variants = {
	visible: {
		opacity: 1,
		scale: 1,
		y: 0,
		transition: { duration: 0.4 },
	},
	hidden: { opacity: 0, scale: 0.75, y: 50 },
};
export const SkillCard = ({ skill }) => {
	const Icon = skill.icon;
	const [current, setCurrent] = useState(0);
	const [ref, isVisible] = useInView({
		threshold: 0.5,
	});
	const controls = useAnimation();

	function animateValue(obj, start, end, duration) {
		let startTimestamp = null;
		const step = (timestamp) => {
			if (!startTimestamp) startTimestamp = timestamp;
			const progress = Math.min(
				(timestamp - startTimestamp) / duration,
				1
			);

			const current = Math.floor(
				progress * (end - start) + start
			);

			setCurrent(current);

			if (progress < 1) {
				window.requestAnimationFrame(step);
			}
		};
		window.requestAnimationFrame(step);
	}

	useEffect(() => {
		if (isVisible) {
			controls.start("visible");
		} else {
			controls.start("hidden");
		}
	}, [controls, isVisible]);

	useEffect(() => {
		const valueElement = document.getElementById(
			skill.label
		);

		animateValue(
			valueElement,
			0,
			skill.percentage,
			750
		);
	}, [isVisible, skill.label, skill.percentage]);

	const circleStyles = {
		transform: `rotate(${current * 3.6}deg)`,
	};

	return (
		<motion.div
			ref={ref}
			animate={controls}
			initial="hidden"
			variants={variants}
			className={` skillCard rounded-lg  w-full mr-4 rounded-lg`}
		>
			<div className="skillCard__label text-sm sm:text-base dark:bg-gradient-to-r dark:to-blue-500 dark:from-indigo-600 bg-gradient-to-l to-stone-800 from-stone-700">
				<Icon fill="var(--primary)" />
				<span className="skillCard__label--text dark:text-stone-950 text-gray-200 ml-2">
					{skill.label}
				</span>
			</div>
			<div className="skillCard__body relative dark:bg-stone-900 bg-white w-full">
				<div
					className="skillCard__circle"
					style={{
						"--num": current,
						"--progressColor":
							current > 60
								? "#11ff00"
								: current > 40
								? "#ffbb00"
								: "#ff4a2b",
					}}
				>
					<svg>
						<circle
							cx="60"
							cy="60"
							r="60"
						></circle>
						<circle
							cx="60"
							cy="60"
							r="60"
						></circle>
					</svg>
					<div className="skillCard__number absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 dark:text-gray-200 text-stone-950">
						<span
							id={skill.label}
							className="text-3xl"
						>
							{current}
						</span>
						%
					</div>
					<div
						className="skill__card__dot"
						style={circleStyles}
					>
						<div className="skill__card__dot--inner"></div>
					</div>
				</div>
			</div>
		</motion.div>
	);
};
