import { useState, useEffect } from "react";
import { IoIosPerson } from "react-icons/io";
import { LuBrainCircuit } from "react-icons/lu";
import { MdWork } from "react-icons/md";
import { IoMdMail } from "react-icons/io";
import debounce from "lodash/debounce";

export const Navigation = () => {
	const [currentIndex, setCurrentIndex] = useState(0);
	const [indicatorLeft, setIndicatorLeft] = useState(0);
	const [screenVw, setScreenVw] = useState(null);

	useEffect(() => {
		let observer;

		const handleIntersection = (iEls) => {
			iEls.forEach((iEl) => {
				if (iEl.isIntersecting) {
					const index = links.findIndex(
						(section) =>
							section.id ===
							"#" + iEl.target.id
					);
					setCurrentIndex(index);
				}
			});
		};

		const updateObserver = () => {
			setScreenVw(window.innerWidth);
			document
				.querySelectorAll("section")
				.forEach((el) => {
					const elHeight =
						el.getBoundingClientRect().height;
					const th = Math.min(
						window.innerHeight / elHeight,
						1
					);
					observer = new IntersectionObserver(
						handleIntersection,
						{
							threshold: th,
							rootMargin: `${th * 110}px`,
						}
					);
					observer.observe(el);
				});
		};

		const debouncedUpdateObserver = debounce(
			updateObserver,
			200
		);

		updateObserver();

		window.addEventListener(
			"resize",
			debouncedUpdateObserver
		);

		return () => {
			if (observer) {
				observer.disconnect();
			}
			window.removeEventListener(
				"resize",
				debouncedUpdateObserver
			);
		};
	}, []);

	useEffect(() => {
		const links = document.querySelectorAll(
			".navigation li"
		);

		links.forEach((link, i) => {
			if (i === currentIndex) {
				const left = link.offsetLeft;
				setIndicatorLeft(left);
			}
		});
	}, [currentIndex, screenVw]);

	const indicatorStyles = {
		left: `${indicatorLeft}px`,
	};

	const links = [
		{ id: "#about", label: "About", icon: IoIosPerson },
		{
			id: "#skills",
			label: "Skills",
			icon: LuBrainCircuit,
		},
		{
			id: "#experience",
			label: "Experience",
			icon: MdWork,
		},
		{
			id: "#contact",
			label: "Contact",
			icon: IoMdMail,
		},
	];

	function scrollToSection(hash) {
		const current = document.querySelector(hash);
		current.scrollIntoView();
	}

	return (
		<nav className="navigation z-10 lg:z-auto fixed border-t-8 lg:border-t-0 border-solid border-gray-100 bottom-0 left-0 w-full lg:relative dark:text-stone-950 text-gray-200 tracking-wider  dark:bg-gradient-to-r dark:to-blue-500 dark:from-indigo-600 bg-gradient-to-l to-stone-800 from-stone-700 lg:rounded-xl">
			<ul className="navigation__links py-2 md:py-0 px-4 lg:px-6 xl:px-12 md:px-12 ">
				{links.map((link, i) => {
					return (
						<li
							key={link.id}
							href={link.id}
							className={`navigation__item w-16 min-h-16 md:w-24 md:min-h-24 ${
								i === currentIndex
									? "active"
									: ""
							}`}
							onClick={() =>
								scrollToSection(link.id)
							}
						>
							<span className="navigation__item--icon flex items-center">
								<link.icon />
							</span>
							<span className="navigation__item--label flex items-center text-xs md:text-base">
								{link.label}
							</span>
						</li>
					);
				})}
				<div
					style={indicatorStyles}
					className="navigation__indicator border-8 min-w-16 w-16 min-h-16 md:min-w-24 md:w-24 md:min-h-24 dark:bg-gradient-to-r dark:to-blue-500 dark:from-indigo-600 bg-gradient-to-l to-stone-800 from-stone-700 border dark:border-stone-950 border-gray-100"
				>
					<div className="navigation__indicator__corner navigation__indicator__corner--1"></div>
					<div className="navigation__indicator__corner navigation__indicator__corner--2"></div>
					<div className="hidden lg:block navigation__indicator__corner navigation__indicator__corner--3"></div>
					<div className="hidden lg:block navigation__indicator__corner navigation__indicator__corner--4"></div>
				</div>
			</ul>
		</nav>
	);
};
