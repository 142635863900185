import { SkillCard } from "./SkillCard";
import { skills } from "../assets/skills";
import { experience } from "../assets/experience";
import { ExperienceCard } from "./ExperienceCard";
import { IoArrowForward } from "react-icons/io5";
import { Button } from "./Button";
import { ContactForm } from "./ContactForm";

export const Main = () => {
	return (
		<main className="dark:text-gray-300 light:text-stone-950 px-6 lg:px-0 lg:w-1/2 flex-grow pt-6 pb-36 xl:pb-12 xl:pt-12 tracking-wider">
			<section
				id="about"
				className="scroll-mt-6 lg:scroll-mt-12"
			>
				<h2 className="text-3xl md:text-4xl">
					Welcome to my portfolio!
				</h2>
				<p className="mt-6 lg:mt-10">
					I'm Wojciech Lehmann, a passionate
					Frontend Developer based in Rybnik,
					Poland. My programming journey began in
					2020 - driven by a passion for
					technology and a strong will to master
					it, I embarked on full-time learning,
					which eventually led me to my first job
					as a Junior Frontend Developer in
					January 2021. Since then, I've been
					gaining experience with a UK-based
					medical company, initially known as
					Fishawack Health, now rebranded to
					Avalere Health via a company called
					Everse, based in Rybnik.
				</p>
				<p className="mt-4">
					I enjoy working with cutting-edge
					frameworks, and Vue is my favorite. I
					have 3 years of commercial experience
					using it, both versions 2 and 3 - it's
					like my programming comfort zone. I also
					find React quite appealing; it's pretty
					cool as well. I'm always up for a
					challenge and eager to learn new
					technologies, whether it's frontend or
					backend I'm up for it!
				</p>
				<p className="mt-4">
					Outside of coding, I like sports -
					football and speedway, playing video
					games, and watching movies, and
					listening to music. But most of all I
					love spending time with my family.
				</p>
				<p className="mt-4">
					That's me in a nutshell! Thanks for
					swinging by! Feel free to snoop around
					my portfolio and hit me up if you're
					keen to connect or collaborate!
				</p>
			</section>
			<section
				id="skills"
				className="scroll-mt-6 lg:scroll-mt-10 mt-10 lg:mt-16"
			>
				<h2 className="text-3xl md:text-4xl">
					Skills
				</h2>
				<div className="grid grid-flow-row gap-6 grid-cols-2 sm:grid-cols-3 lg:grid-cols-2 xl:grid-cols-3 mt-6 lg:mt-10">
					{skills.map((skill) => (
						<SkillCard
							key={skill.label}
							skill={skill}
						/>
					))}
				</div>
			</section>
			<section
				id="experience"
				className="scroll-mt-6 lg:scroll-mt-10 lg:mt-16 mt-10"
			>
				<h2 className="text-3xl md:text-4xl">
					Experience
				</h2>
				<div className="lg:mt-10 mt-6">
					{experience.map((job, i) => (
						<ExperienceCard
							key={i + job.title}
							title={job.title}
							company={job.company}
							type={job.type}
							range={job.range}
							copy={job.copy}
							location={job.location}
							links={job.links}
						/>
					))}
				</div>
				<div className="lg:mt-10 mt-6">
					<Button
						href="./CV.pdf"
						target="_blank"
						label="View full resume"
						icon={IoArrowForward}
					/>
				</div>
			</section>
			<section
				id="contact"
				className="scroll-mt-6 lg:scroll-mt-16 lg:mt-16 mt-10"
			>
				<h2 className="text-3xl md:text-4xl">
					Get in touch
				</h2>
				<ContactForm></ContactForm>
			</section>
		</main>
	);
};
