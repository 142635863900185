import {
	FaHtml5,
	FaCss3Alt,
	FaVuejs,
	FaReact,
	FaNodeJs,
} from "react-icons/fa";
import {
	SiJavascript,
	SiTypescript,
	SiNuxtdotjs,
	SiJest,
	SiTailwindcss,
	SiExpress,
	SiMongodb,
} from "react-icons/si";

export const skills = [
	{
		label: "HTML",
		percentage: 90,
		icon: FaHtml5,
	},
	{
		label: "CSS/SCSS",
		percentage: 90,
		icon: FaCss3Alt,
	},
	{
		label: "JavaScript",
		percentage: 80,
		icon: SiJavascript,
	},
	{
		label: "Vue.js",
		percentage: 80,
		icon: FaVuejs,
	},
	{
		label: "Tailwind CSS",
		percentage: 70,
		icon: SiTailwindcss,
	},
	{
		label: "Nuxt.js",
		percentage: 50,
		icon: SiNuxtdotjs,
	},
	{
		label: "Jest",
		percentage: 50,
		icon: SiJest,
	},
	{
		label: "React",
		percentage: 50,
		icon: FaReact,
	},
	{
		label: "TypeScript",
		percentage: 30,
		icon: SiTypescript,
	},
	{
		label: "Node.js",
		percentage: 30,
		icon: FaNodeJs,
	},
	{
		label: "Express",
		percentage: 30,
		icon: SiExpress,
	},
	{
		label: "MongoDB",
		percentage: 30,
		icon: SiMongodb,
	},
];
