import { FaLinkedin } from "react-icons/fa";
import { FaGithub } from "react-icons/fa";

export const HeaderCard = () => {
	return (
		<div className="flex flex-col md:flex-row flex-wrap justify-center items-center dark:bg-gradient-to-r dark:to-blue-500 dark:from-indigo-600 bg-gradient-to-l to-stone-800 from-stone-700 pt-6 pb-6 px-6 lg:px-12 lg:pb-16 lg:rounded-lg">
			<img
				className="w-20 md:w-24 rounded-full"
				src="/me.jpg"
				alt="Wojtek Lehmann"
			/>
			<div className="ml-8 max-lg:ml-0 grow text-center dark:text-stone-950 text-gray-200">
				<h1 className="text-2xl md:text-3xl mt-2 md:mt-4 font-semibold">
					Wojciech Lehmann
				</h1>
				<h2 className="text-xl md:text-2xl uppercase mt-2 md:mt-4 tracking-wide">
					Frontend Developer
				</h2>
				<div className="mt-2 md:mt-4 flex justify-center mx-auto">
					<a
						href="https://www.linkedin.com/in/wojciech-lehmann-764523225/"
						target="_blank"
						className="cursor-pointer inline"
						rel="noreferrer"
					>
						<FaLinkedin size="32" />
					</a>
					<a
						href="https://github.com/LWojtek"
						target="_blank"
						className="cursor-pointer inline ml-4"
						rel="noreferrer"
					>
						<FaGithub size="32" />
					</a>
				</div>
			</div>
		</div>
	);
};
